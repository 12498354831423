import React, { useState, useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import { alertActions, genealogyActions } from '../../actions';
import { apiConstants } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../Common/Dashboard/Header';
import Footer from '../Common/Dashboard/Footer';
import Sidebar from '../Common/Dashboard/Sidebar';
import DiamondStructure from '../DiamondStructure/DiamondStructure';
import { useHistory, useLocation } from "react-router-dom";

const Genealogy = (props) => {
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const [alertRes, setAlertRes] = useState('');
    // const [siteConstants, setSiteConstants] = useState([]);
    const [arrMatrix, setArrMatrix] = useState([]);
    const [previousData, setPreviousData] = useState([])
    const { alert, language, positionByNumber } = useSelector(
        state => ({
            alert: state.alert,
            language: state.language,
            positionByNumber: state.positionByNumber
        })
    );

    useEffect(() => {
        if (positionByNumber !== undefined && positionByNumber !== null) {
            if (Object.keys(positionByNumber).length > 0) {
                if (positionByNumber.positionByNumber) {
                    // setDiamondPosition(positionByNumber.positionByNumber.data)
                    // var dumm_arr = [...arrMatrix];  --commented it on 1 april 2022 so that all diamond not shown 
                    var dumm_arr = []

                    if (positionByNumber.positionByNumber.data.data.length > 0) {
                        positionByNumber.positionByNumber.data.data.map((item) => {
                            return dumm_arr.push(item)
                        })

                    }

                    setArrMatrix(dumm_arr)
                }
            }
        }
    }, [positionByNumber])

    useEffect(() => {
        if (Object.keys(language).length > 0) {
            // setSiteConstants(language.siteConstants);
        }
    }, [language]);

    useEffect(() => {
        setArrMatrix([])
        dispatch(alertActions.clear());
        let sessionPlan = sessionStorage.getItem('planType');
        let positionAllApiName = apiConstants.POSITION_ALL;
        let positionNoApiName = apiConstants.POSITION_BY_NUMBER;

        if ((sessionPlan && Object.keys(sessionPlan).length > 0 && JSON.parse(sessionPlan).status === 1)) {
            positionAllApiName = apiConstants.PLAN_POSITION_ALL;
            positionNoApiName = apiConstants.PLAN_POSITION_BY_NUMBER;
        }

        let temp = previousData;
        let obj = { positionNumber: props.location.state.state, plan: { _id: sessionPlan ? JSON.parse(sessionPlan)._id : '' } }
        temp.push(obj)
        setPreviousData(temp);
        if (props.location.state.input === "view") {
            dispatch(genealogyActions.positionByNumber(positionNoApiName, obj));
        } else {
            dispatch(genealogyActions.positionByNumber(positionAllApiName, obj));
        }
        return () => {
            setArrMatrix([])
            dispatch({ type: 'POSITION_RESTORE_DEFAULT' });
        }
    }, []);

    const handleClose = (index) => {

        var dumm_arr = [...arrMatrix];
        dumm_arr.splice(index, 1)
        return setArrMatrix(dumm_arr)
    }


    const backHandler = () => {
        if (previousData.length > 1) {
            let sessionPlan = sessionStorage.getItem('planType');
            let positionNoApiName = apiConstants.POSITION_ALL;

            if ((sessionPlan && Object.keys(sessionPlan).length > 0 && JSON.parse(sessionPlan).status === 1)) {
                positionNoApiName = apiConstants.PLAN_POSITION_ALL;
            }

            if (location.pathname === '/genealogy') {
                let temp = previousData;
                temp.splice(temp.length - 1, 1);
                setPreviousData(temp)
                dispatch(genealogyActions.positionByNumber(positionNoApiName, temp[temp.length - 1]));
            }
        } else {
            history.goBack();
        }
    }

    const previousHandler = (e) => {
        let temp = previousData;
        temp.push(e)
        setPreviousData(temp);
    }

    useEffect(() => {
        if (alert !== undefined && alert !== null) {
            if (Object.keys(alert).length > 0) {

                let tempErr;
                if (alert.type === 'alert-success' && alert.message !== undefined) {
                    tempErr = [{ 'type': alert.type, 'message': alert.message }]
                }
                else if (alert.message !== undefined) {
                    if (alert.message.errors !== undefined && alert.message.errors !== null) {
                        let keys = Object.keys(alert.message.errors)
                        tempErr = { 'type': alert.type, message: alert.message.errors[keys] }
                    }
                }

                setAlertRes(tempErr);
                setTimeout(() => {
                    setAlertRes('')
                }, 3000);
            }
        }
    }, [alert]);

    return (
        <>
            <div className="main-wrap">
                <Header />
                <div className="dashboard-wraper">
                    <Sidebar />
                    <div className="content-wrap">
                        {alertRes !== '' ?
                            alertRes.length > 0 ? alertRes.map((data, index) => {
                                return (
                                    <Alert key={index} variant={data.type === 'alert-danger' ? 'danger' : 'success'} className="alertBox">
                                        {data.message}
                                    </Alert>)
                            }) : '' : ''}
                        <div className="dashboard-content p">

                            <div className="dash-body-blk">
                                <i className="m-5 fas fa-arrow-left" onClick={() => { backHandler() }}></i>
                                <div className="dash-report-blk px-xl-4 px-2 pt-1">
                                    {arrMatrix !== undefined && arrMatrix !== null ?
                                        arrMatrix.length > 0 ?
                                            arrMatrix.map((data, index) => {
                                                return (
                                                    <div key={index} style={{ 'display': 'inline-block', margin: '5px' }}>
                                                        {index !== 0 ? <i className="far fa-times-circle" onClick={() => handleClose(index)}></i> : ''}
                                                        <DiamondStructure key={index} matrixPosition={data} previousBoard={(e) => previousHandler(e)} />
                                                    </div>
                                                )
                                            })
                                            : <p className='m-5'>Downlines not added for this position</p>
                                        : 'issue1'}
                                </div>
                            </div>
                            <Footer />
                        </div>

                    </div>
                </div>

            </div>
        </>
    );
}

export default Genealogy;
