import { apiConstants, userConstants } from "../constants";
import * as commonAction from "./commonAction";
import { commonService, userService } from "../services";
import { alertActions } from "./";
import { history } from "../helpers";
//import { apiConstants } from "../constants/api.constants";
export const userActions = {
  getUsersList,
  userSignup,
  userLogin,
  userOtp,
  activateAccount,
  sendEmail,
  getCurrentUser,
  changeLanguage,
  addMembers,
  checkReferLink,
  getAdminReferal,
  getCountryList,
  userProfile,
  uploadProfileImg,
  getProfle,
  changePassword,
  generateReferalLink,
  userVerify,
  personalisedEmail,
  emailUpdate,
  getSetting,
  getFounderPosition,
  getCount,
  sendTokenEmail,
  emailVerification,
  nextInLineUsersTable,
  nextInLineClassicUsersTable
};

//-- Get User List function
function getUsersList(apiName, obj) {
  return dispatch => {
    dispatch(commonAction.request(userConstants.GET_USERS_REQUEST, obj));

    commonService.withToken(apiName, obj).then(
      user => {
        dispatch(commonAction.success(userConstants.GET_USERS_SUCCESS, user));
      },
      error => {
        dispatch(commonAction.failure(userConstants.GET_USERS_FAILURE, error));
        //dispatch(alertActions.error(error.response.data.error.msg));
      }
    );
  };
}

function getCountryList(apiName) {
  return dispatch => {
    dispatch(commonAction.request(userConstants.GET_COUNTRY_LIST_REQUEST));

    commonService.getDataWithoutToken(apiName).then(
      user => {
        dispatch(commonAction.success(userConstants.GET_COUNTRY_LIST_SUCCESS, user));
      },
      error => {
        dispatch(commonAction.failure(userConstants.GET_COUNTRY_LIST_FAILURE, error));
        //dispatch(alertActions.error(error.response.data.error.msg));
      }
    );
  };
}

function personalisedEmail(apiName, obj) {
  return dispatch => {
    dispatch(commonAction.request(userConstants.PERSONALISED_EMAIL_REQUEST, obj));

    commonService.withToken(apiName, obj).then(
      user => {
        dispatch(commonAction.success(userConstants.PERSONALISED_EMAIL_SUCCESS, user));
        dispatch(alertActions.success("Mail sended successfully..."));
      },
      error => {
        dispatch(commonAction.failure(userConstants.PERSONALISED_EMAIL_FAILURE, error));
        //dispatch(alertActions.error(error.response.data.error.msg));
      }
    );
  };
}

function emailUpdate(apiName, obj) {
  return dispatch => {
    dispatch(commonAction.request(userConstants.MAIL_UPDATE_REQUEST, obj));

    commonService.withToken(apiName, obj).then(
      user => {
        dispatch(commonAction.success(userConstants.MAIL_UPDATE_SUCCESS, user));
      },
      error => {
        dispatch(commonAction.failure(userConstants.MAIL_UPDATE_FAILURE, error));
        //dispatch(alertActions.error(error.response.data.error.msg));
      }
    );
  };
}

//-- signup function
function userSignup(apiName, obj) {
  return dispatch => {
    dispatch(commonAction.request(userConstants.USER_SIGNUP_REQUEST));

    userService.userSignup(apiName, obj).then(
      user => {
        dispatch(commonAction.success(userConstants.USER_SIGNUP_SUCCESS, user));
        dispatch(alertActions.success("Signup successfully..."));
        dispatch(alertActions.success("Please verify with your email for login..."));
        setTimeout(function () { dispatch(alertActions.clear()) }, 3000);
        history.push('/login');
      },
      error => {
        dispatch(commonAction.failure(userConstants.USER_SIGNUP_FAILURE, error));
        if (error.response !== undefined) {
          dispatch(alertActions.error(error.response.data.error));
        }
        // dispatch(alertActions.error(error.response.data.error.msg));
        setTimeout(function () { dispatch(alertActions.clear()) }, 3000);
      }
    );
  };
}

//-- Email-verification function
function emailVerification(apiName, obj) {
  return dispatch => {
    dispatch(commonAction.request(userConstants.USER_EMAIL_VERIFY_REQUEST));

    userService.userEmailVerify(apiName, obj).then(
      user => {
        dispatch(commonAction.success(userConstants.USER_EMAIL_VERIFY_SUCCESS, user));
        dispatch(alertActions.success("Email Verification successfully..."));
        setTimeout(function () { dispatch(alertActions.clear()) }, 3000);

      },
      error => {
        dispatch(commonAction.failure(userConstants.USER_EMAIL_VERIFY_FAILURE, error));
        if (error.response !== undefined) {
          dispatch(alertActions.error(error.response.data.error));
        }
        // dispatch(alertActions.error(error.response.data.error.msg));
        setTimeout(function () { dispatch(alertActions.clear()) }, 3000);
      }
    );
  };
}

//-- login function
function userLogin(apiName, obj) {
  return dispatch => {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('planType');
    dispatch(commonAction.request(userConstants.USER_LOGIN_REQUEST));

    userService.userLogin(apiName, obj).then(
      user => {
        // console.log("user", user)
        if (user.status === 200) {
          let token = user.data.data.token;
          let userData = user.data.data.user;
          setTimeout(function () { dispatch(alertActions.clear()) }, 3000);

          sessionStorage.setItem('user', JSON.stringify(userData));
          sessionStorage.setItem('poolStatusDetails', userData.pool_status)
          sessionStorage.setItem('planType', JSON.stringify(user.data.purchasePlan))

          //  dispatch login function response so that ad can be displayed 
          dispatch(commonAction.success(userConstants.USER_LOGIN_SUCCESS, token));
          // history.push("/dashboard");
          if (user.data && user.data.status === false) {
            dispatch(alertActions.error("Please verify your email address ,then Login again!"))
          }
          else if (user.data && user.data.nomineeCount === 0) {
            if (user.data && !user.data.wallet_condition) {

              dispatch(alertActions.error("Logged in, Please update your wallet with new system and fill your Nominee Details..."));
              // dispatch(alertActions.success("Hi DJ7 FAMILY ,We have identified an issue on the Platform that needs to be addressed ASAP.Members can still continue to buy positions and they will be assigned according to the order they were bought once we have resolved the issue on the site.We will resume all presentations by Monday as we now focus to resolve this challenge.We sincerely apologise for any inconvenience caused.DJ7 TEAM"));

            }

            else {

              dispatch(alertActions.error("Logged in, Please Fill your Nominee Details..."));
              // dispatch(alertActions.success("Hi DJ7 FAMILY ,We have identified an issue on the Platform that needs to be addressed ASAP.Members can still continue to buy positions and they will be assigned according to the order they were bought once we have resolved the issue on the site.We will resume all presentations by Monday as we now focus to resolve this challenge.We sincerely apologise for any inconvenience caused.DJ7 TEAM"));

            }
          }
          else {
            if (user.data && !user.data.wallet_condition) {

              dispatch(alertActions.error("Logged in, Please update your wallet with new system and fill your Nominee Details..."));
              // dispatch(alertActions.success("Hi DJ7 FAMILY ,We have identified an issue on the Platform that needs to be addressed ASAP.Members can still continue to buy positions and they will be assigned according to the order they were bought once we have resolved the issue on the site.We will resume all presentations by Monday as we now focus to resolve this challenge.We sincerely apologise for any inconvenience caused.DJ7 TEAM"));

            }
            else {
              dispatch(alertActions.success("Login successfully..."));

              // dispatch(alertActions.success("Hi DJ7 FAMILY ,We have identified an issue on the Platform that needs to be addressed ASAP.Members can still continue to buy positions and they will be assigned according to the order they were bought once we have resolved the issue on the site.We will resume all presentations by Monday as we now focus to resolve this challenge.We sincerely apologise for any inconvenience caused.DJ7 TEAM"));
            }
          }
        }
      },
      error => {
        dispatch(commonAction.failure(userConstants.USER_LOGIN_FAILURE, error));
        if (error.response !== undefined) {
          dispatch(alertActions.error(error.response.data.error));
        }
        else {
          dispatch(alertActions.error('Network Error'));
        }
        setTimeout(function () { dispatch(alertActions.clear()) }, 3000);
      }
    );
  };
}

function userVerify(apiName, obj) {
  return dispatch => {
    dispatch(commonAction.request(userConstants.VERIFY_USER_REQUEST));

    commonService.withToken(apiName, obj).then(
      user => {
        dispatch(commonAction.success(userConstants.VERIFY_USER_SUCCESS, user));
      },
      error => {
        dispatch(commonAction.failure(userConstants.VERIFY_USER_FAILURE, error));
        if (error.response !== undefined) {
          dispatch(alertActions.error(error.response.data.error));
        }
        else {
          dispatch(alertActions.error('Network Error'));
        }
      }
    );
  };
}

function nextInLineUsersTable(apiName, obj) {
  return dispatch => {
    dispatch(commonAction.request(userConstants.NEXT_IN_LINE_REQUEST));

    commonService.withToken(apiName, obj).then(
      user => {
        dispatch(commonAction.success(userConstants.NEXT_IN_LINE_SUCCESS, user));
      },
      error => {
        dispatch(commonAction.failure(userConstants.NEXT_IN_LINE_FAILURE, error));
        if (error.response !== undefined) {
          dispatch(alertActions.error(error.response.data.error));
        }
        else {
          dispatch(alertActions.error('Network Error'));
        }
      }
    );
  };
}

function nextInLineClassicUsersTable(apiName, obj) {
  return dispatch => {
    dispatch(commonAction.request(userConstants.NEXT_IN_LINE_CLASSIC_REQUEST));

    commonService.withToken(apiName, obj).then(
      user => {
        dispatch(commonAction.success(userConstants.NEXT_IN_LINE_CLASSIC_SUCCESS, user));
      },
      error => {
        dispatch(commonAction.failure(userConstants.NEXT_IN_LINE_CLASSIC_FAILURE, error));
        if (error.response !== undefined) {
          dispatch(alertActions.error(error.response.data.error));
        }
        else {
          dispatch(alertActions.error('Network Error'));
        }
      }
    );
  };
}

function userOtp(apiName, obj) {
  return dispatch => {
    dispatch(commonAction.request(userConstants.VERIFY_OTP_REQUEST));

    commonService.withToken(apiName, obj).then(
      user => {
        dispatch(commonAction.success(userConstants.VERIFY_OTP_SUCCESS, user));
      },
      error => {
        dispatch(commonAction.failure(userConstants.VERIFY_OTP_FAILURE, error));
        if (error.response !== undefined) {
          dispatch(alertActions.error(error.response.data.error));
        }
        else {
          dispatch(alertActions.error('Otp Error'));
        }
      }
    );
  };
}

function getCurrentUser(apiName, obj) {
  return dispatch => {
    dispatch(commonAction.request(userConstants.GET_CURRENT_USER_REQUEST));

    userService.getCurrentUser(apiName, obj).then(
      user => {
        dispatch(commonAction.success(userConstants.GET_CURRENT_USER_FAILURE, user));
      },
      error => {
        dispatch(commonAction.failure(userConstants.GET_CURRENT_USER_FAILURE, error));
        if (error.response !== undefined) {
          dispatch(alertActions.error(error.response.data.error));
        }
        else {
          dispatch(alertActions.error('Network Error'));
        }
      }
    );
  };
}

function activateAccount(apiName, obj) {
  return dispatch => {
    dispatch(commonAction.request(userConstants.ACCOUNT_ACTIVE_REQUEST, obj));
    userService.activateAccount(apiName, obj).then(
      user => {
        dispatch(commonAction.success(userConstants.ACCOUNT_ACTIVE_SUCCESS, user));
      },
      error => {
        dispatch(commonAction.failure(userConstants.ACCOUNT_ACTIVE_ERROR, error));
        if (error.response !== undefined) {
          dispatch(alertActions.error(error.response.data.error));
        }
        else {
          dispatch(alertActions.error('Network Error'));
        }
      }
    );
  };
}

function addMembers(apiName, obj) {
  return dispatch => {
    dispatch(commonAction.request(userConstants.ADD_MEMBERS_REQUEST, obj));
    userService.withToken(apiName, obj).then(
      user => {
        dispatch(commonAction.success(userConstants.ADD_MEMBERS_SUCCESS, user));
        dispatch(alertActions.success("Mail Send to member Successfully..."));
      },
      error => {
        dispatch(commonAction.failure(userConstants.ADD_MEMBERS_FAILURE, error));

        if (error.response !== undefined) {
          dispatch(alertActions.error(error.response.data.error));
        }
        else {
          dispatch(alertActions.error('Network Error'));
        }
      }
    );
  };
}

function checkReferLink(apiName, obj) {
  return dispatch => {
    dispatch(commonAction.request(userConstants.CHECK_REFER_LINK_REQUEST, obj));
    commonService.withToken(apiName, obj).then(
      user => {
        dispatch(commonAction.success(userConstants.CHECK_REFER_LINK_SUCCESS, user));
      },
      error => {
        dispatch(commonAction.failure(userConstants.CHECK_REFER_LINK_FAILURE, error));
        if (error.response !== undefined) {
          dispatch(alertActions.error(error.response.data.error));
        }
        else {
          dispatch(alertActions.error("Network Error"));
        }
      }
    );
  };
}

function sendEmail(apiName, obj) {
  return dispatch => {
    dispatch(commonAction.request(userConstants.SEND_EMAIL_REQUEST, obj));
    userService.resendEmail(apiName, obj).then(
      user => {
        dispatch(commonAction.success(userConstants.SEND_EMAIL_SUCCESS, user));
        dispatch(alertActions.success("Mail Send Successfully..."));
      },
      error => {
        dispatch(commonAction.failure(userConstants.SEND_EMAIL_FAILURE, error));
        if (error.response !== undefined) {
          dispatch(alertActions.error(error.response.data.error));
        }
        else {
          dispatch(alertActions.error('Network Error'));
        }
      }
    );
  };
}

function sendTokenEmail(apiName, obj) {
  return dispatch => {
    dispatch(commonAction.request(userConstants.SEND_TOKEN_EMAIL_REQUEST, obj));
    commonService.withToken(apiName, obj).then(
      user => {
        dispatch(commonAction.success(userConstants.SEND_TOKEN_EMAIL_SUCCESS, user));
        dispatch(alertActions.success("Mail Send Successfully..."));
      },
      error => {
        dispatch(commonAction.failure(userConstants.SEND_TOKEN_EMAIL_FAILURE, error));
        if (error.response.data !== undefined) {
          dispatch(alertActions.error(error.response.data.error));
        }
        else {
          dispatch(alertActions.error("Network Error"));
        }
      }
    );
  };
}

function getAdminReferal(apiName, obj) {
  return dispatch => {
    dispatch(commonAction.request(userConstants.ADMIN_REFERAL_REQUEST, obj));
    commonService.getDataWithoutToken(apiName, obj).then(
      user => {
        dispatch(commonAction.success(userConstants.ADMIN_REFERAL_SUCCESS, user));
      },
      error => {
        dispatch(commonAction.failure(userConstants.ADMIN_REFERAL_FAILURE, error));
        if (error.response !== undefined) {
          dispatch(alertActions.error(error.response.data.error));
        }
        else {
          dispatch(alertActions.error('Network Error'));
        }
      }
    );
  };
}

function uploadProfileImg(apiName, userData) {

  return dispatch => {
    dispatch(
      commonAction.request(userConstants.UPLOAD_IMAGE_REQUEST, userData)
    );
    userService.uploadProfileImg(apiName, userData).then(
      user => {
        dispatch(
          commonAction.success(userConstants.UPLOAD_IMAGE_SUCCESS, user.data.data)
        );
        dispatch(alertActions.success("Image uploaded successfully"));
        dispatch(getProfle(apiConstants.GET_PROFILE))
        setTimeout(function () {
          dispatch(alertActions.clear());
        }, 3000);
      },
      error => {
        dispatch(
          commonAction.failure(userConstants.UPLOAD_IMAGE_FAILURE, error)
        );

        if (error.response !== undefined) {
          dispatch(alertActions.error(error.response.data.error));
        }
        else {
          dispatch(alertActions.error('Network Error'));
        }
      }
    );
  };
}

//-- Update user Profile
function userProfile(apiName, userData) {

  return dispatch => {
    dispatch(commonAction.request(userConstants.UPDATE_PROFILE_REQUEST, userData));
    commonService.withTokenPut(apiName, userData).then(
      user => {
        dispatch(commonAction.success(userConstants.UPDATE_PROFILE_SUCCESS, user));
        dispatch(alertActions.success(user.data.message));
        if (user.success === false) {
          dispatch(commonAction.failure(userConstants.UPDATE_PROFILE_FAILURE, user.message));
          dispatch(alertActions.error(user.message));
        }
        else {
          dispatch(alertActions.success("Profile Updated successfully..."));
          dispatch(getProfle(apiConstants.GET_PROFILE))
        }
      },
      error => {
        if (error.response !== undefined) {
          dispatch(alertActions.error(error.response.data.error));
        }
        else {
          dispatch(alertActions.error('Network Error'));
        }
      }
    );
  };
}

// get profile details
function getProfle(apiName, userData) {
  return dispatch => {
    dispatch(
      commonAction.request(userConstants.GET_PROFILE_REQUEST, userData)
    );
    commonService.getDataWithToken(apiName, userData).then(
      user => {
        dispatch(commonAction.success(userConstants.GET_PROFILE_SUCCESS, user));
      },
      error => {
        dispatch(commonAction.failure(userConstants.GET_PROFILE_FAILURE, error));
        if (error.response !== undefined) {
          dispatch(alertActions.error(error.response.data.error));
        }
        else {
          dispatch(alertActions.error('Network Error'));
        }
      }
    );
  };
}

//-- Change Password
function changePassword(apiName, userData) {
  return dispatch => {
    dispatch(
      commonAction.request(userConstants.CHANGE_PASSWORD_REQUEST, userData)
    );
    userService.changePassword(apiName, userData).then(
      user => {
        dispatch(
          commonAction.success(userConstants.CHANGE_PASSWORD_SUCCESS, user)
        );
        if (user.success === false) {
          dispatch(alertActions.error(user.message));
        } else {
          dispatch(alertActions.success("Password Updated Successfully"));
          setTimeout(() => {
            sessionStorage.removeItem('token');
            sessionStorage.removeItem('user');
            history.push('/login');
          }, 3000);

        }
        setTimeout(function () {
          dispatch(alertActions.clear())
        }, 3000);

      },
      error => {
        dispatch(commonAction.failure(userConstants.CHANGE_PASSWORD_FAILURE, error));

        if (error.response !== undefined) {
          dispatch(alertActions.error(error.response.data.error));
        }
        else {
          dispatch(alertActions.error('Network Error'));
        }
        setTimeout(function () { dispatch(alertActions.clear()) }, 3000);
      }


    );
  };
}

function changeLanguage(language) {
  return { type: language };
}

function generateReferalLink(apiName, userData) {
  return dispatch => {
    dispatch(
      commonAction.request(userConstants.GENERATE_LINK_REQUEST, userData)
    );
    commonService.getDataWithToken(apiName, userData).then(
      user => {
        dispatch(commonAction.success(userConstants.GENERATE_LINK_SUCCESS, user));
      },
      error => {
        dispatch(commonAction.failure(userConstants.GENERATE_LINK_FAILURE, error));
        if (error.response !== undefined) {
          dispatch(alertActions.error(error.response.data.error));
        }
        else {
          dispatch(alertActions.error('Network Error'));
        }
        setTimeout(function () { dispatch(alertActions.clear()) }, 3000);
      }
    );
  };
}

function getSetting(apiName) {
  return dispatch => {
    dispatch(commonAction.request(userConstants.GET_SETTING_REQUEST));

    commonService.getDataWithToken(apiName).then(
      user => {
        dispatch(commonAction.success(userConstants.GET_SETTING_SUCCESS, user));
      },
      error => {
        dispatch(commonAction.failure(userConstants.GET_SETTING_FAILURE, error));
        //dispatch(alertActions.error(error.response.data.error.msg));
      }
    );
  };
}

function getFounderPosition(apiName) {
  return dispatch => {
    dispatch(commonAction.request(userConstants.GET_FOUNDER_POSITION_REQUEST));

    commonService.getDataWithToken(apiName).then(
      user => {
        dispatch(commonAction.success(userConstants.GET_FOUNDER_POSITION_SUCCESS, user));
      },
      error => {
        dispatch(commonAction.failure(userConstants.GET_FOUNDER_POSITION_FAILURE, error));
        //dispatch(alertActions.error(error.response.data.error.msg));
      }
    );
  };
}


function getCount(apiName) {
  return dispatch => {
    dispatch(commonAction.request(userConstants.GET_COUNT_REQUEST));

    commonService.getDataWithoutToken(apiName).then(
      user => {
        dispatch(commonAction.success(userConstants.GET_COUNT_SUCCESS, user));
      },
      error => {
        dispatch(commonAction.failure(userConstants.GET_COUNT_FAILURE, error));
        //dispatch(alertActions.error(error.response.data.error.msg));
      }
    );
  };
}