import React, { useState, useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import { alertActions, genealogyActions } from '../../actions';
import { apiConstants } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../Common/Dashboard/Header';
import Footer from '../Common/Dashboard/Footer';
import Sidebar from '../Common/Dashboard/Sidebar';
import TreeStructure from './TreeStructure';
import { useHistory, useLocation } from "react-router-dom";

const Genealogy = (props) => {
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const [alertRes, setAlertRes] = useState('');
    const [arrMatrix, setArrMatrix] = useState([]);
    const [initialMatrix, setInitialMatrix] = useState([])
    const [levelCount, setLevelCount] = useState(0);
    const [previousData, setPreviousData] = useState([])
    const [selectedLevel, setSelectedLevel] = useState(1);
    const { alert, positionByNumber } = useSelector(
        state => ({
            alert: state.alert,
            positionByNumber: state.positionByNumber
        })
    );


    useEffect(() => {
        if (positionByNumber && Object.keys(positionByNumber).length > 0) {
            if (positionByNumber.positionByNumber) {
                let dumm_arr = [];
                let tempArr = [];

                if (positionByNumber.positionByNumber.data.data.length > 0) {
                    positionByNumber.positionByNumber.data.data.map((item) => {
                        return dumm_arr.push(item)
                    })

                }
                if (dumm_arr && dumm_arr.length > 0) {
                    for (let i = 0; i < 7; i++) {
                        tempArr.push(i)
                    }
                }
                setLevelCount(tempArr)
                setArrMatrix(dumm_arr)
                setInitialMatrix(dumm_arr)
            }
        }
    }, [positionByNumber])

    useEffect(() => {
        setArrMatrix([])
        dispatch(alertActions.clear());
        let sessionPlan = sessionStorage.getItem('planType');
        let positionAllApiName = apiConstants.SUPER_MATRIX_POSITION_ALL;
        let positionNoApiName = apiConstants.SUPER_MATRIX_POSITION_BY_NUMBER;

        let temp = previousData;
        let obj = { positionNumber: props.location.state.state, plan: { _id: sessionPlan ? JSON.parse(sessionPlan)._id : '' } }
        temp.push(obj)
        setPreviousData(temp);

        if (props.location.state.input === "view") {
            dispatch(genealogyActions.positionByNumber(positionNoApiName, obj));
        } else {
            dispatch(genealogyActions.positionByNumber(positionAllApiName, obj));
        }
        return () => {
            setArrMatrix([])
            dispatch({ type: 'POSITION_RESTORE_DEFAULT' });
        }
    }, []);

    const backHandler = () => {
        if (previousData.length > 1) {
            let sessionPlan = sessionStorage.getItem('planType');
            let positionNoApiName = apiConstants.POSITION_ALL;

            if ((sessionPlan && Object.keys(sessionPlan).length > 0 && JSON.parse(sessionPlan).status === 1)) {
                positionNoApiName = apiConstants.PLAN_POSITION_ALL;
            } else if ((sessionPlan && Object.keys(sessionPlan).length > 0 && JSON.parse(sessionPlan).status === 2)) {
                positionNoApiName = apiConstants.MATRIX_POSITION_ALL;
            } else if ((sessionPlan && Object.keys(sessionPlan).length > 0 && JSON.parse(sessionPlan).status === 3)) {
                positionNoApiName = apiConstants.SUPER_MATRIX_POSITION_ALL;
            }

            if (location.pathname === '/genealogy') {
                let temp = previousData;
                temp.splice(temp.length - 1, 1);
                setPreviousData(temp)
                dispatch(genealogyActions.positionByNumber(positionNoApiName, temp[temp.length - 1]));
            }

        } else {
            history.goBack();
        }
    }

    const previousHandler = (e) => {
        let temp = previousData;
        temp.push(e)
        setPreviousData(temp);
    }

    useEffect(() => {
        if (alert && Object.keys(alert).length > 0) {

            let tempErr;
            if (alert.type === 'alert-success' && alert.message !== undefined) {
                tempErr = [{ 'type': alert.type, 'message': alert.message }]
            }
            else if (alert.message !== undefined) {
                if (alert.message.errors !== undefined && alert.message.errors !== null) {
                    let keys = Object.keys(alert.message.errors)
                    tempErr = { 'type': alert.type, message: alert.message.errors[keys] }
                }
            }

            setAlertRes(tempErr);
            setTimeout(() => {
                setAlertRes('')
            }, 3000);

        }
    }, [alert]);

    const getLevelMatrix = (index) => {
        let tempArr = [...initialMatrix];
        setArrMatrix(tempArr.filter((x) => x.userCurrentRound + 1 >= index));
        setSelectedLevel(index)
    }

    return (
        <>
            <div className="main-wrap">
                <Header />
                <div className="dashboard-wraper">
                    <Sidebar />
                    <div className="content-wrap">
                        {
                            alertRes && alertRes.length > 0 ? alertRes.map((data, index) => {
                                return (
                                    <Alert key={index} variant={data.type === 'alert-danger' ? 'danger' : 'success'} className="alertBox">
                                        {data.message}
                                    </Alert>)
                            }) : ''}
                        <div className="dashboard-content p">

                            <div className="dash-body-blk">
                                <i className="m-5 fas fa-arrow-left" style={{ cursor: "pointer" }} onClick={() => { backHandler() }}></i>
                                <div className="dash-report-blk px-xl-4 px-2 pt-1">
                                    <div className="d-flex flex-wrap">
                                        {levelCount && levelCount.length > 0 && levelCount.map((x, index) => {
                                            return (
                                                <button className={"levelButton m-3 " + (selectedLevel == index + 1 ? 'levelSelected' : '')} onClick={() => getLevelMatrix(index + 1)}> Matrix {index + 1}</button>
                                            )
                                        })}
                                    </div>

                                    {arrMatrix && Array.isArray(arrMatrix) && arrMatrix.length > 0 ?
                                        // arrMatrix.map((data, index) => {
                                        <div className='text-center h-60vh position-relative'>
                                            <TreeStructure width="170%" height="70vh" translateX="650" translateY="120" levelNumber={selectedLevel} matrixPosition={arrMatrix} previousBoard={(e) => previousHandler(e)} />
                                        </div>
                                        // })
                                        : <p className='m-5'>Downlines not added for this position</p>
                                    }
                                </div>
                            </div>
                            <Footer />
                        </div>

                    </div>
                </div>

            </div>
        </>
    );
}

export default Genealogy;
